.forum-tos {
    font-size: 1.1em;

    a {
        text-decoration: underline;
    }

    em {
        font-style: normal;
        text-decoration: underline;
    }

    p {
        position: relative;
        margin-left: 1cm;
        line-height: 1.2;

        &.indent {
            margin-left: 2cm;
        }

        &.indent-2 {
            margin-left: 3cm;
        }
    }

    li {
        p {
            position: relative;
            margin-left: 0;
            line-height: 1.2;
        }
    }

    .section-id {
        position: absolute;
        left: -1cm;
    }
}

.checkbox-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
        height: 100%;
    }

    input[type="checkbox"] {
        margin-right: .5rem;
    }
}
