.block.about-page {
    .block-content {
        padding: 0;

        img {
            width: 100%;
        }

        p {
            padding: 8px;
        }
    }
}
