@import "../colors";

.block.daughter-search {
    .daughter-search-query {
        width: 80%;
        float: left;
    }

    .submit-button {
        width: calc(20% - 3px);
        float: right;
    }
}
