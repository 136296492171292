@use "sass:color";
@import "../../umbrella/scss/_partials/clearfix";
@import "../../umbrella/scss/mixins/ellipsis";
@import "../../umbrella/scss/mixins/breakpoint";
@import "colors";
@import "settings";

.header .block {
    box-shadow: none;
}

.block[data-can-be-collapsed="True"] {
    .block-header h2 {
        cursor: pointer;
    }
}

.block {
    width: 100%;
    box-shadow: -2px 5px 18px -7px rgba(0 0 0 / 25%);

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .block-header {
        @extend %clearfix;

        padding: 0 6px;
        border-radius: $border-radius $border-radius 0 0;
        color: $white;

        @include breakpoint("before-medium") {
            padding-top: .3125rem;
            padding-bottom: .3125rem;

            .icon {
                margin: -12px -24px -18px -17px;
                padding: 12px 24px 18px 17px;
            }
        }

        h2,
        h3 {
            width: calc(100% - 22px);
            margin: 0;
            float: left;
            overflow: hidden;
            font-size: 12px;
            font-weight: bold;
            line-height: 22px;
            text-overflow: ellipsis;
            white-space: nowrap;

            @include breakpoint("medium") {
                cursor: default;
            }
        }

        .icon {
            display: block;
            position: absolute;
            box-sizing: content-box;
            width: 10px;
            height: 10px;
            float: right;
            background-repeat: no-repeat;
            background-position: center;
            text-decoration: none;
            cursor: pointer;

            &.edit {
                background-image: url("../static/rainbow/images/blocks/edit.svg");
            }

            &.remove {
                background-image: url("../static/rainbow/images/blocks/remove.svg");
            }

            &.pin {
                display: none;
                background-image: url("../static/rainbow/images/blocks/pin.svg");
            }

            &.down {
                display: none;
            }

            &.up,
            &.down {
                position: relative;
                right: 7px;

                svg {
                    fill: $white;
                }
            }
        }

        a {
            color: $white;
            text-decoration: none;
        }
    }

    .block-content,
    .widget-content {
        @extend %clearfix;

        padding: 4px;
        border-top-width: 0;
        border-radius: 0 0 $border-radius $border-radius;
        background-color: $white;

        p {
            margin: 0;
        }

        .image:first-child {
            border-top-width: 0;
        }
    }

    a:hover {
        color: $block-hover;
    }

    &.invisible {
        h2,
        h3,
        .block-header {
            display: none;
        }

        .block-content {
            border-radius: $border-radius;
        }

        .block-content,
        .widget-content {
            border-top-width: 1px;
        }
    }

    &.overflow-hidden {
        .block-content {
            overflow: hidden;
        }
    }

    &.completely-invisible {
        display: none;
    }

    &.filtered-search {
        &.html {
            .block-content,
            .widget-content {
                background: transparent;
            }
        }

        &.daughterpages-search-result {
            .block-content {
                background-color: $white;

                li a {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .image {
                    border: 0;
                }
            }
        }
    }

    &.nu-search-result {
        .link-image {
            width: 40px;
            height: 40px;
        }

        .image {
            .text,
            .label {
                display: block;
            }
        }
    }

    &.autoweek-search-result {
        .text {
            @include ellipsis(2, 15px);
        }

        .price,
        .manufacture {
            display: block;
            max-height: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .label {
        color: $label;
        font-size: 11px;
    }

    &.collapsed {
        .icon.up {
            display: none;
        }

        .icon.down {
            display: block;
        }

        .block-content {
            display: none;
        }
    }
}

.block.banner {
    box-shadow: none;

    .block-content,
    .widget-content {
        background-color: transparent;
    }
}

$block-header-colors: (
    "red": $block-red,
    "pink": $block-pink,
    "purple": $block-purple,
    "blue": $block-blue,
    "light-blue": $block-lightblue,
    "orange": $block-orange,
    "dark-red": $block-darkred,
    "green": $block-green,
    "light-green": $block-lightgreen,
    "scooter": $block-scooter,
    "taupe-grey": $block-taupegrey,
    "slate-grey": $block-slategrey,
    "yellow": $block-yellow,
    "white": $block-white,
    "black": $block-black,
    "gold": $block-gold,
);

@each $name, $color in $block-header-colors {
    .block.#{$name} .block-header,
    .block.#{$name} h2 {
        background-color: $color;
    }
}

.block.white {
    h2 {
        color: $block-blue;
    }

    .block-header .icon svg {
        fill: $block-blue;
    }
}

$block-background-colors: (
    "default": $block-content-background,
    "blue": $block-widget-content-background,
    "white": $block-white,
);

@each $name, $color in $block-background-colors {
    .block.bg-#{$name} {
        .block-content {
            background-color: $color;
        }

        .bg-highlight {
            background-color: color.adjust($color, $lightness: 3%);
        }
    }
}

.block.bg-transparent {
    .block-content {
        border: 0;
        background-color: transparent;
    }
}

.block.bg-like-5th-column {
    box-shadow: none;

    .block-content {
        background-color: $column5-background-color;
    }
}

.visible-on {
    display: none !important;

    &.visible-on-five {
        @include breakpoint("ultra") {
            display: block !important;
        }
    }

    &.visible-on-four {
        @include breakpoint("only-large") {
            display: block !important;
        }
    }

    &.visible-on-three {
        @include breakpoint("only-medium") {
            display: block !important;
        }
    }

    &.visible-on-two {
        @include breakpoint("only-small") {
            display: block !important;
        }

        @include breakpoint("only-pmedium") {
            display: block !important;
        }
    }

    &.visible-on-one {
        @include breakpoint("tiny") {
            display: block !important;
        }
    }
}
