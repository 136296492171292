@import "../../../umbrella/scss/_partials/clearfix";
@import "../colors";

.location {
    @extend %clearfix;

    &.location-enabled {
        .location-icon {
            background: url("/static/rainbow/images/location/location.svg") center no-repeat;
        }
    }

    .location-form {
        display: flex;
        width: 100%;
        padding-top: 0;
        padding-right: 10px;
    }

    .location-icon {
        width: 16px;
        height: 20px;
        margin: 4px 0 0 7px;
        float: left;
        background: url("/static/rainbow/images/location/location-gray.svg") center no-repeat;
    }

    .location-query {
        width: 100%;
        height: 30px;
        padding-right: 16px;
        padding-left: 8px;
        border: 0;
        background: url("/static/rainbow/images/location/arrow-down.svg") no-repeat right;
        color: $block-white !important;
        font-size: 13px;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    .location-header {
        height: 20px;
        padding: 2px 0 0 6px;
        clear: left;
        border-top: 1px solid $block-black;
        border-bottom: 1px solid $block-black;
        background-color: $block-blue;
        color: $block-white;
        font-weight: bold;
        cursor: default;
    }

    .ui-autocomplete {
        position: absolute;
        z-index: 1;
        left: 0 !important;
        width: 100%;
        border-right: 1px solid $block-black;
        border-bottom: 1px solid $block-black;
        border-left: 1px solid $block-black;
        background-color: $block-white;
    }

    .ui-menu-item {
        height: 20px;
        padding: 2px 0 0 6px;
        clear: left;
        overflow: hidden;
        text-overflow: ellipsis;

        a {
            display: block;
        }

        &.focused-item {
            background-color: $block-lightblue;

            a {
                color: $block-white;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.location-input {
    position: relative;
    width: 100%;
    height: 30px;
    float: left;
    background-color: var(--blue-faded);
    font-size: 13px;
    cursor: pointer;
}
