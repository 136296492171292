@import "../../../../umbrella/scss/mixins/breakpoint";

.header .search-box {
    width: 100%;
    margin-left: 3px;

    @include breakpoint("before-medium") {
        position: relative;
        bottom: -90px;
        left: -8px;
        width: 100vw;
        margin: 0 auto;

        .search-field {
            min-width: 0;
        }
    }

    .block-header {
        display: none;
    }

    .block-content {
        border: 0;
        background: transparent !important;

        @include breakpoint("small") {
            padding: 16px;
        }
    }

    form {
        .search-field {
            width: 100%;
            font-size: 16px;

            @include breakpoint("large") {
                font-size: inherit;
            }
        }

        .submit-button {
            right: -32px;
            line-height: 28px;
        }
    }
}
