@import "../../../umbrella/scss/mixins/breakpoint";
@import "../colors";

$mobile-max-viewport-width: 500px;

.block.news-rss {
    ul {
        width: 65.5%;
        margin: -6px 0 0 4px;
        float: left;

        @media only screen and (max-width: $mobile-max-viewport-width) {
            width: 100%;
            margin: 6px 0 0;
        }

        li {
            a {
                display: block;
                height: 100%;
                padding: 3.25px 0 3.25px 15px;
                overflow: hidden;
                background-image: url("/static/rainbow/images/news-rss/baseline-keyboard-arrow-right-20px-black.svg");
                background-repeat: no-repeat;
                background-position: 0 4px;
                background-size: 15px 15px;
                text-overflow: ellipsis;
                white-space: nowrap;

                @media only screen and (max-width: $mobile-max-viewport-width) {
                    padding: 3.25px 0 3.25px 10px;
                    background-position: -5px 3px;
                }

                &:hover {
                    background-image: url("/static/rainbow/images/news-rss/baseline-keyboard-arrow-right-20px-blue.svg");
                    color: $block-black;
                    font-weight: bold;
                    text-decoration: none;
                }
            }
        }

        li.image {
            background-repeat: no-repeat;
            background-position: right 4px;

            @media only screen and (max-width: $mobile-max-viewport-width) {
                a {
                    padding-bottom: 0;
                }
            }

            img {
                max-width: 50px;
                height: 30px;
                float: right;
            }
        }
    }

    .block-content {
        padding: 8px;
    }

    .branded-link-image {
        @media only screen and (max-width: $mobile-max-viewport-width) {
            display: none;
        }
    }

    .branded-link-image-mobile {
        @media only screen and (min-width: $mobile-max-viewport-width) {
            display: none;
        }
    }

    .branded-link-title {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .branded-link-label {
        color: $branded-link-label-color;
        font-size: 11px;

        @media only screen and (max-width: $mobile-max-viewport-width) {
            padding-top: 15px;
            padding-right: 5px;
            float: right;
        }
    }

    .feed-image {
        width: 33.3%;
        height: 138px;
        float: left;

        @media only screen and (max-width: $mobile-max-viewport-width) {
            width: 100%;
            height: 215px;
        }
    }
}
