@import "../../../../umbrella/scss/_partials/clearfix";
@import "../../../../umbrella/scss/mixins/breakpoint";
@import "../../colors";
@import "../../columns";
@import "../../settings";

$overlay-color: $black;
$search-background-primary: $white;
$search-background-secondary: $grey;
$arrow-height: 15px;
$column-width: 195px;

.lightbox-container {
    display: none;
    position: absolute;
    z-index: 1000;
    top: 0;
    width: 100%;
    text-align: center;

    // The overlay is used to gray out most of the rest of the page
    .overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .6;
        background: $overlay-color;

        @include breakpoint("medium") {
            display: block;
        }
    }

    // The lightbox sits on top of the overlay, and contains the search results.
    .lightbox {
        display: inline-block;
        position: relative;

        // The z-index should be lower than searchbox to make sure its on top
        z-index: 10;
        width: 100%;
        background: $search-background-primary;
        text-align: left;

        @include breakpoint("large") {
            width: 90%;
        }
    }

    .header {
        width: 100%;
        height: 61px;
        padding: 0 10px;
        background-color: $search-background-secondary;
        line-height: 61px;

        .header-row {
            display: flex;
            align-items: center;
            max-width: $max-content-width;
            margin: 0 auto;

            @include breakpoint("medium") {
                .logo {
                    display: none;
                }

                .search-history.visible {
                    display: flex;
                }
            }

            >* {
                margin: 0 6px;
            }

            .header-button {
                display: flex;
                align-items: center;

                > :not(:first-child) {
                    margin-left: 7px;
                }

                .text {
                    color: $lightbox-header-button-subtitle;
                    font-size: 14px;
                }

                .icon {
                    width: 32px;
                    height: 32px;
                    padding: 8px;
                    border: 1px solid $lightbox-header-button-border;
                    border-radius: $border-radius;
                    background-position: center;
                }
            }

            .search-history {
                display: none;

                &.search-history-back .icon {
                    background-image: url("../../../static/rainbow/images/lightbox/chevron_left.svg");
                }

                &.search-history-next .icon {
                    background-image: url("../../../static/rainbow/images/lightbox/chevron_right.svg");
                }
            }

            .logo {
                width: 100%;
                height: 32px;
                background-image: url("../../../static/rainbow/images/logo/sp_logo_new.svg");
                background-repeat: no-repeat;
            }

            .spacer {
                flex: 1;
            }

            .close .icon {
                background-image: url("../../../static/rainbow/images/lightbox/close.svg");
            }
        }

        // This creates a little triangular 'arrow' between the input box and the results.
        &::after {
            content: "";
            display: none;
            width: 0;
            margin: 0 auto;
            border-width: $arrow-height $arrow-height 0 $arrow-height;
            border-style: solid;
            border-color: $search-background-secondary transparent $search-background-secondary transparent;

            @include breakpoint("medium") {
                display: block;
            }
        }
    }

    .content-wrapper {
        max-width: $max-content-width + 20px; // 20px come from the padding below
        margin: 0 auto;
        padding: 0 16px;

        .content {
            @extend %clearfix;

            // align with header keeping the column width calculation intact
            // column widths should add up to 100% with the padding
            margin: 0 calc($column-spacing / -2);
            padding: $column-spacing 0;

            .block {
                margin-bottom: $block-spacing;
            }
        }
    }

    .column {
        width: 100%;
        padding: 0 calc($column-spacing / 2);
        float: left;
    }

    .column-top,
    .column-bottom {
        padding: 0 calc($column-spacing / 2);
    }

    .column-bottom {
        display: block;
        clear: both;
    }

    .column-bottom .block {
        position: relative;
        left: 8px;
        width: calc(100% - 16px);
    }

    .column-1,
    .column-5 {
        @include breakpoint("medium") {
            width: 33.3%;
        }

        @include breakpoint("large") {
            width: 20%;
        }
    }

    .column-wide {
        padding: 0;

        @include breakpoint("medium") {
            width: 33.3%;
        }

        @include breakpoint("large") {
            width: 60%;
        }

        .column {
            @include breakpoint("large") {
                width: 33.3%;
            }
        }
    }

    .section-debug {
        position: absolute;
        right: 4px;
        color: $white;
        line-height: 22px;
    }

    .block-header {
        h2,
        h3 {
            width: 100%;
        }

        .icon {
            display: none;
        }
    }

    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 16px;
        background-color: $search-background-secondary;
        font-size: 14px;

        @include breakpoint("medium") {
            flex-direction: row;
        }

        .submit-button {
            margin-top: 16px;

            @include breakpoint("medium") {
                margin-top: 0;
                margin-left: 22px;
            }

            .icon {
                background-image: url("../../../static/rainbow/images/blocks/search.svg");
            }
        }
    }
}

// Adjustments to align the search box with its position in the lightbox
.lightbox-open {
    @include breakpoint("before-medium") {
        .header-section .search-box {
            bottom: 48px;
        }

        .top-banner {
            display: none;
        }

        .header .sub-menu {
            display: none;
        }
    }
}

.font-bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}
