// Inbetween step for Tailwind changes,
// unifies and normalizes all colors
@import "color-map";

// Used colors coming from Umbrella, backported for encapsulation
$white: color("white");
$black: color("black");
$searchbox-autocompete-border: color("gray-600");

// Rainbow colors
$orange: color("orange");
$grey: color("gray-1000");
$darkgrey: color("gray-400");
$darkblue: color("blue-200");
$blue: color("blue-300");
$lightblue: color("blue-400");
$brightgreen: color("green-400");
$block-blue: color("blue-300");
$block-lightblue: color("blue-400");
$block-red: color("red-600");
$block-darkred: color("red-500");
$block-orange: color("orange");
$block-grey: color("gray-600");
$block-bg-grey: color("gray-1000");
$block-green: color("block-green");
$block-lightgreen: color("green-500");
$block-scooter: color("blue-600");
$block-taupegrey: color("gray-taupe");
$block-slategrey: color("gray-slate");
$block-yellow: color("yellow");
$block-grey-transparant: rgba(255 255 255 / 70%);
$block-white: color("white");
$block-black: color("black");
$block-gold: #d19f4b;
$block-pink: color("pink");
$block-purple: color("purple");
$block-content-border: color("blue-700");
$block-content-background: color("yellow-100");
$block-image-border-top: color("blue-700");
$block-widget-content-background: color("blue-800");
$block-widget-separator: color("blue-700");
$block-widget-button-background-color: $block-blue;
$block-widget-input-text-border-color: color("gray-900");
$block-widget-button-text-color: color("white");
$block-widget-form-border: color("gray-900");
$block-more-color: color("blue-300");
$block-more-hover: color("red-400");
$block-more-background: color("white");
$block-more-icon-background: color("blue-300");
$block-hover: color("red-400");
$block-button: color("blue-400");
$block-button-hover: color("blue-500");
$label: color("red-700");
$highlight: #ff0;
$link-text: color("blue-400");
$background-colors: (
    "black": color("gray-bg"),
    "blue": color("blue-bright"),
    "green": color("green-400"),
    "red": color("red-200"),
);
$text--muted: color("gray-400");
$input-border: color("gray-800");
$input-background--disabled: color("gray-1000");
$rain-forecast-button-background-color: color("white");
$rain-forecast-button-icon-color: color("white");
$rain-forecast-button-background-hover: color("blue-800");
$rain-forecast-button-icon-background-color: color("blue-600");
$traffic-map-tab-shadow-color: color("gray-600");
$traffic-map-road-number-a-border-color: color("red-800");
$traffic-map-road-number-a-background-color: color("red-800");
$traffic-map-road-number-a-color: color("white");
$traffic-map-road-number-n-border-color: color("yellow");
$traffic-map-road-number-n-background-color: color("yellow");
$traffic-map-road-number-n-color: color("black");
$traffic-map-tab-unselected-background-color: color("gray-1000");
$traffic-map-tab-border-color: color("gray-700");
$weather-widget-temperature-color: color("gray-200");
$weather-forecast-even-tablerow-background: color("blue-800");
$weather-forecast-footer-background: color("blue-faded");
$weather-forecast-footer-color: color("white");
$insurance-tab-background-color: color("blue-700");
$insurance-tab-active-background-color: color("orange");
$insurance-tab-hover-background-color: $block-blue;
$insurance-tab-text-color: $block-blue;
$poll-results-graph-background: color("orange");
$poll-results-graph-border: color("gray-600");
$not-found-warning-background: color("red-1000");
$not-found-warning-border: color("red-900");
$personal-links-input-disabled-color: color("gray-1000");
$personal-links-page-numbers-color: color("gray-500");
$column5-background-color: color("gray-1000");
$startpagina-balk: color("blue-300");
$top-banner-background-color: color("gray-1000");
$branded-link-label-color: color("gray-500");
$footer-background-color: color("gray-1000");
$footer-link-color: color("blue-300");
$footer-link-color-hover: color("gray-400");
$more-pages-overlay-color: color("gray-200");
$more-pages-column-footer-bg-color: color("gray-1000");

// Colors for alert boxes
$alert-success-text: color("green-200");
$alert-success-background: color("green-700");
$alert-success-border: color("green-600");
$alert-error-text: color("red-300");
$alert-error-background: color("red-1000");
$alert-error-border: color("red-900");

// Lightbox colors
$lightbox-header-button-border: color("gray-700");
$lightbox-header-button-subtitle: color("gray-300");

// Forms
$form-border: color("gray-200");
$form-search-field-color: color("gray-100");
$startpagina-knop-hover: color("orange-400");
$startpagina-knop: color("orange-500");
$list-item-hover-background: color("blue-100");
