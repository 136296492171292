@import "../colors";
@import "../../../umbrella/scss/mixins/ellipsis";

.block.daily-horoscope {
    .zodiac-hidden {
        display: none;
    }

    .zodiac-unknown {
        display: none;
        width: calc(100% + 10px);
        margin: -5px;
        background-color: $white;

        li {
            padding: 5px;

            a {
                color: $block-more-color;
            }

            a:hover {
                color: $block-hover;
                text-decoration: none;
            }
        }
    }

    .title {
        color: $black;
        font-size: 12px;
        font-weight: bold;
        text-decoration: none;
    }

    .summary {
        @include ellipsis(3);

        height: 45px;
        margin: 2px 0;
        padding: 0;
    }

    .more {
        color: $block-more-color;
        font-style: italic;
    }

    .more:hover {
        color: $block-hover;
    }
}
