$browser-upgrade-warning-background: #feefee;
$browser-upgrade-warning-border: #b1a7a6;

.browser-upgrade {
    display: none;
    z-index: 15;
    font-size: 14px;

    .row {
        margin-bottom: 10px;
        padding: 15px;
        border: 1px solid $browser-upgrade-warning-border;
        border-top-width: 0;
        border-radius: 0 0 3px 3px;
        background-color: $browser-upgrade-warning-background;
    }

    a {
        text-decoration: underline;
    }
}
