.light-2-column-first-wide {
    .responsive-column {
        // to offset the padding of the first column:
        // the padding there is necessary because in the current model
        // it is included in the column width calculation
        margin: 0 calc($column-spacing / 2) 0 calc($column-spacing / -2);

        @include breakpoint("large") {
            width: 78.8%;
        }

        .section-wide {
            width: 100%;
        }
    }

    .column2 {
        float: left;

        @include breakpoint("large") {
            width: 21.2%;
        }

        .section {
            width: 100%;
            padding: 0;
        }
    }
}
