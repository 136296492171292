// Elementary units
$unit: 8px;
$halfunit: calc($unit / 2);
$doubleunit: $unit * 2;

// Page
$page-top-margin: $doubleunit;
$page-bottom-margin: $unit * 2;

// Block
$block-side-padding: $unit * 1.5;
$block-top-padding: $unit;
$block-top-padding-non-text: $unit + $halfunit;
$block-bottom-padding: $unit + $halfunit;

// Block sections and other elements
$block-section-bottom-margin: $doubleunit;
$block-section-bottom-margin-non-text: $doubleunit - $halfunit;
$header-bottom-margin: $unit;
$header-bottom-margin-non-text: $unit + $halfunit;
$icon-right-margin: $unit;
$small-icon-right-margin: $halfunit * 1.5;
$input-label-margin: $halfunit;
$input-bottom-margin: $unit;
$last-item-bottom-margin: 0;
$default-line-height: 1.6em;
