:root {
    --transparent: transparent;
    --current: currentcolor;
    --white: #fff;
    --black: #000;
    --pink: #f20094;
    --purple: #b80090;
    --yellow: #febb07;
    --yellow-100: #fdecca;
    --rgba-black-8: rgba(0 0 0 / 8%);
    --rgba-black-25: rgba(0 0 0 / 25%);
    --rgba-gray-70: rgba(120 120 120 / 70%);
    --gold: #d19f4b;

    // Custom colors not part of scale(s)
    --bg-blue: #00a2e8;
    --bg-gray: #333;
    --block-taupe: #b6b39c;
    --block-slate: #768197;
    --blue-faded: #6a7c85;

    // Color scales
    // Generated using colorbox and palleton
    --gray-1000: #0a0a0a;
    --gray-900: #292929;
    --gray-800: #474747;
    --gray-700: #666;
    --gray-600: #858585;
    --gray-500: #a3a3a3;
    --gray-400: #c2c2c2;
    --gray-300: #d1d1d1;
    --gray-200: #e0e0e0;
    --gray-100: #f0f0f0;
    --blue-900: #013842;
    --blue-800: #005884;
    --blue-700: #0f6a98;
    --blue-600: #0090b1;
    --blue-500: #00a6c6;
    --blue-400: #3cbcd3;
    --blue-300: #bbe1f3;
    --blue-200: #def;
    --blue-100: #e2f7fb;
    --green-900: #063314;
    --green-800: #124421;
    --green-700: #155724;
    --green-600: #008d16;
    --green-500: #22b14c;
    --green-400: #00c924;
    --green-300: #c3e6cb;
    --green-200: #d4edda;
    --green-100: #e3fceb;
    --red-900: #880015;
    --red-800: #721c24;
    --red-700: #b22222;
    --red-600: #a24b4b;
    --red-500: #d6241a;
    --red-400: #d03d48;
    --red-300: #ea2127;
    --red-200: #f5c6cb;
    --red-100: #f8d7da;
    --red-50: #fbeaec;
    --orange-100: #331010;
    --orange-200: #6c261e;
    --orange-300: #ae4529;
    --orange-400: #d9632d;
    --orange-500: #eb7c2c;
    --orange-600: #f48240;
    --orange-700: #f98b58;
    --orange-800: #feab95;
    --orange-900: #feb7aa;
    --orange-1000: #ffbe88;
}
