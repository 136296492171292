@import "../block";
@import "../colors";

$link-spacing: 4px;

.block.filtered-search {
    .block-content {
        padding: 0;
    }

    li {
        margin: 0 .375rem;
        padding: calc($link-spacing / 2) 0;

        &:last-child {
            margin-bottom: 8px;
        }

        &:first-child:not([class*="more"]) {
            padding-top: $link-spacing;

            &.image {
                padding-top: 0;
            }
        }

        &:first-child[class*="more"] {
            margin: 0;
        }

        // should apply for each link that contains an image
        &.more,
        &.image {
            a {
                display: flex;
                align-items: center;
                justify-content: center;

                .link-image {
                    display: block;
                    background: center no-repeat;
                    background-size: 100%;
                }

                .title,
                .link-text {
                    display: block;
                    flex: 1;
                }
            }
        }

        &.more {
            margin: $link-spacing 0 0 0;
            padding: $link-spacing .375rem;
            background-color: $block-more-background;
            color: $block-more-color;

            &:last-child {
                border-radius: 0 0 $border-radius $border-radius;
            }

            a {
                color: $block-more-color;

                &:hover {
                    color: $block-more-hover;
                    text-decoration: underline;
                }

                .link-image {
                    width: 40px;
                    height: 40px;
                    background-color: $block-more-icon-background;
                }
            }
        }

        &.image {
            @mixin link-image($width, $height) {
                .link-image {
                    flex: 0 0 $width;
                    width: $width;
                    height: $height;

                    img {
                        width: inherit;
                        height: inherit;
                    }
                }
            }

            @mixin full-width-image($height) {
                margin: 0;
                padding: 0;

                a {
                    padding: 0 !important;
                }

                .link-image {
                    width: 100%;
                    height: $height;
                    margin-right: 0;
                    background-position: top;

                    img {
                        width: inherit;
                        height: $height;
                    }
                }

                .link-text,
                .title,
                .text {
                    display: none;
                }
            }

            flex: 0 0 40px;
            margin-top: 1px;
            padding: 0 5px 0 0;

            &:not(:last-child) {
                margin-bottom: 2px;
            }

            &.image-20x20 {
                @include link-image(20px, 20px);
            }

            &.image-40x40 {
                @include link-image(40px, 40px);
            }

            &.image-30x50 {
                @include link-image(50px, 30px);
            }

            &.image-60x80 {
                @include link-image(80px, 60px);
            }

            &.image-40x194 {
                @include full-width-image(40px);
            }

            &.image-80x194 {
                @include full-width-image(80px);
            }

            &.image-120x194 {
                @include full-width-image(120px);
            }

            &.image-194x194 {
                @include full-width-image(194px);
            }
        }

        &.multiple-links {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: .125rem;

            a {
                display: inline;

                .link-text {
                    display: inline-block;
                }
            }
        }
    }
}

.filtered-search {
    .image-40x194,
    .image-80x194,
    .image-120x194,
    .image-194x194 {
        .link-image {
            min-width: 194px;
        }
    }
}
