// ---- SVG Icon Helper ----------------------------------------------------------------------------------------

// Configure for the required font, see font metadata at https://seek-oss.github.io/capsize/
// Current values are for Roboto font

$font-cap-height: 1456;
$font-em-height: 2048;
$font-ascender-height: 1900;
$font-descender-height: 500;

// Icon sizes
$icon-height: 1em;
$icon-height--xs: .75em;
$icon-height--sm: .875em;
$icon-scale--lg: 1.125;
$icon-scale--xl: 1.25;
$icon-scale--2xl: 1.5;
$icon-scale--2\.5xl: 1.75;
$icon-scale--3xl: 1.875;
$icon-scale--4xl: 2.25;

// Calculations for centring vertically
$font-line-height: $font-ascender-height + $font-descender-height;
$font-cap-center-from-top: $font-line-height - calc(($font-cap-height / 2) + $font-descender-height);
$font-cap-center-from-top-in-em: calc($font-cap-center-from-top / $font-em-height) * 1em;
$icon-distance-from-top: $font-cap-center-from-top-in-em - calc($icon-height / 2);

@include layer("utilities") {
    .icon {
        line-height: 0;

        svg {
            width: auto;
            height: inherit;
            fill: currentcolor;
        }

        /*
        Add class .fixedwidth to make the icons have a fixed 1.25em width.
        This is useful when there's the need to center icons vertically.
        Override with a larger multiplier when required by the max width of the icons used.
        Inspired by https://fontawesome.com/how-to-use/on-the-web/styling/fixed-width-icons
        */
        &.fixedwidth {
            width: $icon-height * 1.25;

            svg {
                width: $icon-height * 1.25;
            }
        }

        &.aligned {
            position: relative;
            top: $icon-distance-from-top;
            line-height: 0;
            vertical-align: top;

            svg {
                height: $icon-height;
            }

            /*
                Add one of the following classes to adjust the size of the icon:
                xs / sm / lg / xl / 2xl / 3xl
            */

            &.icon-xs {
                top: $font-cap-center-from-top-in-em - calc($icon-height--xs / 2);

                svg {
                    height: $icon-height--xs;
                }
            }

            &.icon-sm {
                top: $font-cap-center-from-top-in-em - calc($icon-height--sm / 2);

                svg {
                    height: $icon-height--sm;
                }
            }

            &.icon-lg,
            &.icon-xl,
            &.icon-2xl,
            &.icon-2\.5xl,
            &.icon-3xl,
            &.icon-4xl {
                top: $icon-distance-from-top;
            }

            /*
                Sizes l, xl and xxl use a different sizing method to prevent influencing their parent's height.
                Their .icon container stays at 1em height and their appearance is scaled using transform: scale();
                Margin is added to ensure proper spacing between text and icon.
            */
            &.icon-lg {
                width: 1 + calc(($icon-scale--lg - 1) / 2) * $icon-height;

                svg {
                    height: $icon-height;
                    transform: scale($icon-scale--lg);
                }
            }

            &.icon-xl {
                width: 1 + calc(($icon-scale--xl - 1) / 2) * $icon-height;

                svg {
                    height: $icon-height;
                    transform: scale($icon-scale--xl);
                }
            }

            &.icon-2xl {
                width: 1 + calc(($icon-scale--2xl - 1) / 2) * $icon-height;

                svg {
                    height: $icon-height;
                    transform: scale($icon-scale--2xl);
                }
            }

            &.icon-2\.5xl {
                width: 1 + calc(($icon-scale--2\.5xl - 1) / 2) * $icon-height;

                svg {
                    height: $icon-height;
                    transform: scale($icon-scale--2\.5xl);
                }
            }

            &.icon-3xl {
                width: 1 + calc(($icon-scale--3xl - 1) / 2) * $icon-height;

                svg {
                    height: $icon-height;
                    transform: scale($icon-scale--3xl);
                }
            }
        }
    }
}
