.row.template-light-1-column {
    @include breakpoint("medium") {
        width: 100%;
    }

    .responsive-column,
    .two-columns {
        width: 100%;

        @include breakpoint("ultra") {
            width: 80%;
        }
    }

    .section {
        width: 100%;
    }
}
