@import "settings";

$block-spacing: 16px;
$column-spacing: 16px;

.row {
    width: 100%;
    max-width: $max-content-width;

    @include breakpoint("pmedium") {
        width: 90%;
    }

    @include breakpoint("large") {
        width: 100%;
    }

    &.columns {
        position: relative;

        @include breakpoint("pmedium") {
            padding: 0;
        }
    }
}

.responsive-column {
    width: 100%;
    float: left;

    @include breakpoint("medium") {
        width: 100%;
    }

    @include breakpoint("large") {
        width: 59.1%;
    }
}

.section {
    width: 100%;
    padding: 0 calc($column-spacing / 2);
    float: left;

    @include breakpoint("small") {
        width: 50%;
    }

    @include breakpoint("medium") {
        width: calc(100% / 3);
        width: 33.33%;
    }

    .block {
        margin-bottom: $block-spacing;
    }

    &.section-wide {
        float: right;

        @include breakpoint("small") {
            width: 100%;
        }

        @include breakpoint("medium") {
            width: calc((100% / 3) * 2);
            width: 66.66%;
        }

        .block {
            width: 100%;
        }

        .block.banner {
            // !important is needed here, since color is set explicitly in the element itself.
            background-color: transparent;
        }
    }
}

.column4 {
    display: none;
    width: 25%;
    float: left;

    @include breakpoint("large") {
        display: block;
    }

    .section {
        width: 100%;
    }

    .block {
        width: 100%;
    }
}

.right-column,
.column5 {
    display: none;
    width: 25%;
    clear: left;
    border-radius: $border-radius;
    background-color: $column5-background-color;

    @include breakpoint("large") {
        display: block;
        float: left;
        clear: none;
    }

    .section {
        width: 100%;
        padding: 16px;
    }

    .block {
        width: 100%;

        // !important is needed here, since color is set explicitly in the element itself.
        background-color: $white !important;
    }

    .block.banner {
        // !important is needed here, since color is set explicitly in the element itself.
        background-color: transparent !important;
    }

    .section:has([data-banner-position="v1"]) [data-banner-position="v4"] {
        margin-top: 1400px;
    }

    .section:not(:has([data-banner-position="v1"])) [data-banner-position="v4"] {
        margin-top: 2000px;
    }
}

// CSS grid layout (currently only for 4 column template)
// Cannot be done in Tailwind until all widgets and column elements are refactored. Would negate all CSS otherwise
.content-wrapper {
    position: relative;
    margin-block-end: 1rem;
    margin-inline: auto;
}

.column-grid {
    display: grid;
    grid-column-gap: 1rem;
    flex-shrink: 0;
    width: 100%;
}

// Grid layouts (with optional top | bottom slots)
.grid-area-col-1,
.grid-area-col-2,
.grid-area-col-3,
.grid-area-col-4 {
    overflow: hidden;
}

.one-column-grid {
    grid-template:
        "col-top"
        "col-1"
        "col-bottom" auto / 1fr;
}

.two-column-grid {
    grid-template:
        "col-top col-top" min-content
        "col-1 col-2" auto
        "col-bottom col-bottom" min-content / 1fr 1fr;
}

.three-column-grid {
    grid-template:
        "col-1 col-top col-top" min-content
        "col-1 col-2 col-3" 1fr
        "col-1 col-bottom col-bottom" min-content / 1fr 1fr 1fr;
}

.four-column-grid {
    grid-template:
        "col-1 col-top col-top col-top" min-content
        "col-1 col-2 col-3 col-4" 1fr
        "col-1 col-bottom col-bottom col-bottom" min-content / 1fr 1fr 1fr 1fr;
}

// Grid areas
.grid-area-col-1 { grid-area: col-1; }
.grid-area-col-2 { grid-area: col-2; }
.grid-area-col-3 { grid-area: col-3; }
.grid-area-col-4 { grid-area: col-4; }
.grid-area-slot-top { grid-area: col-top; }
.grid-area-slot-bottom { grid-area: col-bottom; }

.grid-area-slot-top:has(> *) {
    margin-block-end: 1rem;
}

.grid-area-slot-bottom:has(> *) {
    margin-block-start: 1rem;
}
