@import "../../../../umbrella/scss/mixins/breakpoint";
@import "../../../../umbrella/scss/_partials/clearfix";
@import "../../block";
@import "../../colors";

.block.search-box {
    position: relative;

    .block-content {
        padding: 16px;
        border-top: 0;
    }

    .search-box-logo {
        display: none;
    }

    .trending-now-label {
        margin-right: .25rem;
        padding-block-start: .25rem;
        text-wrap: nowrap;

        @include breakpoint("only-small") {
            display: none;
        }
    }

    .suggestion-link {
        margin-inline-end: .25rem;
        padding: .25rem;
        border-radius: .125rem;
        background-color: var(--orange-500);
        color: white;
        text-wrap: nowrap;

        @include breakpoint("only-small") {
            max-width: 25ch;
            padding: .5rem;
            overflow-x: hidden;
            text-overflow: ellipsis;

            &:nth-child(n + 6) {
                display: none;
            }
        }
    }

    .suggestion-links {
        display: flex;
        margin-block-start: .5rem;
        overflow-x: hidden;

        @include breakpoint("only-small") {
            flex-wrap: wrap;
            row-gap: .25rem;
        }
    }

    form {
        $submit-width-text: 75px;

        @extend %clearfix;

        display: flex;
        position: relative;

        // The searchbox should have a z-index higher than the lightbox and header
        // to make sure it's not hidden by them.
        z-index: 200;
        align-items: center;
        padding: 0;
        border: solid $block-widget-form-border;
        border-width: 1px 0 1px 1px;
        border-radius: $border-radius;

        .search-field {
            flex: 1;
            height: 35px;
            padding: 9px;
            border: 0;
            border-radius: $border-radius 0 0 $border-radius;
            background: $white;
            color: $form-search-field-color;
            font-size: 13px;

            &::placeholder {
                font-style: italic;
            }
        }

        .reset-search {
            display: none;
            width: 16px;
            height: 16px;
            margin: 0 9.5px 0 -25.5px;
            background: url("../../../static/rainbow/images/search-box/clear.svg") no-repeat center;
            text-decoration: none;
            cursor: pointer;
        }

        .submit-button {
            width: 35px;
            padding: 0;
            border-radius: 0 $border-radius $border-radius 0;

            @include breakpoint("large") {
                width: auto;
                padding: 0 22px;
            }

            .icon {
                background-image: url("../../../static/rainbow/images/blocks/search.svg");
            }

            .text {
                display: none;
                width: $submit-width-text;

                @include breakpoint("large") {
                    display: inline;
                }
            }
        }
    }
}
