@import "../../../rainbow/scss/colors";
@import "./media-lightbox";

.block.photo-block {
    .block-content {
        padding: 0;
    }

    img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }

    .subscript {
        margin: 8px;
        color: $block-black;
        font-style: italic;

        a {
            color: $blue;
            text-decoration: underline;

            &:hover {
                color: $block-hover;
            }
        }
    }
}
