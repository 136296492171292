@import "../../../umbrella/scss/mixins/breakpoint";
@import "../colors";

.block.banner {
    text-align: center;

    &.show-border {
        padding: 8px 0;
        border: none;
    }

    .ad-widget > * {
        margin: 9px 0 7px;
    }

    .ad-widget[data-banner-position="v5"] > * {
        margin: 0 0 7px;
    }
}

.horizontal-position {
    .banner {
        border: 0;
    }

    &.top-banner .banner {
        padding: 0 8px;
    }

    &.bottom-banner .banner {
        padding: 0;
        line-height: 0;
    }
}

.skyscraper.v2,
.block.banner.v2 {
    position: absolute;
    top: 1300px;
}

.block.banner.v5 {
    display: none;

    @include breakpoint("large") {
        display: block;
        position: absolute;
        top: 480px - 100px - 8px;  // -100px offset for the top banner, -8px for the content padding
        left: -160px - 16px;
        width: 160px;
    }
}

.light-5-column-top-3-center .block.banner.v5 {
    display: none;

    @include breakpoint("large") {
        display: block;
        position: absolute;
        top: 0;
        left: -176px;
        width: 160px;

        img {
            margin-top: 0;
        }
    }
}

.light-5-column-top-3-center .block.banner.v4 .ad-widget {
    justify-content: flex-start;
}
