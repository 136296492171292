@import "../colors";
@import "weather";

.block.weather-traffic {
    @extend %block-weather;

    &.white .block-header h2 {
        color: $blue;
    }
}
