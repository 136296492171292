@import "../../rainbow/scss/colors";

.template-light-4-column {
    background: $top-banner-background-color;
}

.thema-information-message {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
}

.thema-search-box {
    @include breakpoint("large") {
        width: 100%;
        float: left;
    }

    .thema-filter {
        z-index: 0;
        margin: 0 auto 12px;
        float: none;
        box-shadow: none;

        .block-header {
            display: none;
        }

        .block-content {
            background-color: $column5-background-color;
        }
    }

    .alphabet-buttons {
        display: none;
        margin: 0 auto 12px;
        text-align: center;

        @include breakpoint("medium") {
            display: block;
        }

        a {
            display: inline-block;
            padding: 9px;
            border: 1px solid $column5-background-color;

            &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }

            &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }

    .alphabet-dropdown {
        margin: 0 0 12px;
        text-align: center;

        @include breakpoint("medium") {
            display: none;
        }

        select {
            padding: 6px;
            border: 1px solid $column5-background-color;
            border-radius: 4px;
        }
    }
}

.thema-overview {
    @include breakpoint("large") {
        position: relative;
        width: 75%;
        padding-right: 16px;
        float: left;
    }

    .categories {
        margin-left: -10px;
    }

    .category-header {
        h2 {
            display: inline;
            float: none;
        }

        .right {
            float: right;
        }

        .submit-button {
            padding: 2px 10px;
            font-size: 13px;

            a:hover {
                color: inherit;
            }
        }
    }

    .category-block {
        padding: 10px;
        float: left;

        @include breakpoint("small") {
            width: calc(100% / 2);

            &:nth-child(2n+1) {
                clear: left;
            }
        }

        @include breakpoint("medium") {
            width: calc(100% / 3);

            &:nth-child(2n+1) {
                clear: none;
            }

            &:nth-child(3n+1) {
                clear: left;
            }
        }

        a {
            display: block;
            border: 1px solid $column5-background-color;
        }

        h2 {
            margin: 20px;
            line-height: 1;
        }

        img {
            width: 100%;
        }
    }
}

.right-column.sticky {
    position: sticky;
    top: 16px;
    margin-bottom: 16px;
}

.left-column {
    display: block !important;
    position: absolute !important;
    top: 0;
    left: -176px !important;
    width: 160px !important;
    height: 100%;
}

.block.thema-list {
    margin-bottom: 16px;

    .block-header {
        background-color: $block-blue;
    }

    .block-content {
        padding-bottom: 8px;
        background-color: $white;

        li {
            margin: 0 8px;
            padding: 4px 0;
        }
    }

    ul {
        @include breakpoint("small") {
            columns: 2;
        }

        @include breakpoint("medium") {
            columns: 3;
        }

        @include breakpoint("large") {
            columns: 4;
        }
    }
}

.themas {
    &.bg-black,
    &.bg-red {
        .alphabet-buttons,
        .category-block,
        .forum-tos {
            a {
                color: $white;
            }
        }
    }
}
