@import "app-shell";
@import "buttons";
@import "typography";
@import "colors";
@import "background-color";
@import "not-found";
@import "columns";
@import "block";
@import "templates/template-light-1-column";
@import "templates/template-light-2-column-first-wide";
@import "templates/template-light-4-column";
@import "templates/template-light-5-column";
@import "templates/template-light-5-column-top-3-left";
@import "templates/template-light-5-column-top-3-center";

.column5 {
    margin-bottom: 16px;
}

::-ms-clear {
    display: none;
}

body {
    background-color: $white;
    font-size: 13px;
    line-height: 15px;
}

.customer-service-content {
    width: 100%;
    float: left;
}

.go-to-top {
    @include breakpoint("medium") {
        display: none;
    }
}

.main-container {
    padding: 16px 8px 0;
}

.template-5-column-top-3-center ~ .main-container {
    padding: 9px 8px 0;
}

.header ~ .template-5-column-top-3-center {
    margin-top: 7px;

    @include breakpoint("before-medium") {
        margin-top: 55px; // search box size + margin
    }
}

.template-light-1-column .horizontal-position.top-banner .banner {
    @include breakpoint("only-small") {
        padding: 0;
    }
}

.template-5-column-top-3-center-banner-outer {
    width: 100%;
    background: $top-banner-background-color;
}

form {
    padding: 2px 0;

    input,
    select {
        height: 28px;
        padding: 2px;
        border: 1px solid $form-border;
        line-height: 28px;
    }
}

/* Reponsive helper classes */
.show-mobile {
    display: none;

    @media only screen and (max-width: 500px) {
        display: block;
    }
}

.hide-mobile {
    display: block;

    @media only screen and (max-width: 500px) {
        display: none;
    }
}
