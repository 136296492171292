$turn-tablet-color: #005884;

.turn-on-tablet {
    display: none;

    @include breakpoint("medium") {
        display: block;
    }

    @include breakpoint("large") {
        display: none;
    }

    p {
        margin: 2px 0;
        color: $turn-tablet-color;
        font-size: 12px;
        text-align: center;
        vertical-align: middle;

        .icon-turn-tablet {
            display: inline-block;
            background: url("/static/app-shell/images/turn-tablet.png") 0 0 no-repeat;
            background-size: cover;
            vertical-align: middle;
        }
    }
}

// Don't show when the device is not tall enough to show all four columns when rotated.
@media (max-height: $breakpoint-before-medium) {
    .turn-on-tablet {
        display: none;
    }
}
