@import "../../../rainbow/scss/widgets/weather";

// when this ticket gets refactored, make sure to get rid of the imported file ^^

.block.weather-block {
    .block-content {
        padding: 0;
    }

    .weather-forecast {
        display: block;
        padding: 5px;
        overflow: auto;
        text-align: center;

        &:hover {
            color: $black;
            text-decoration: none;
        }

        .weather-day {
            width: 50%;
            float: left;

            &:first-child {
                border-right: 1px solid $white;
            }

            .weather-icon {
                height: 70px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;

                .section-wide {
                    height: 65px;
                }
            }

            .weather-temperature {
                color: $block-blue;
                font-size: 18px;
                font-weight: bold;
            }

            .weather-summary {
                padding: 0 0 10px;
                font-size: 12px;
            }
        }
    }

    .detailed-link {
        display: block;
        margin: 8px 0 0;
        padding: 8px;
        background: $block-more-background;
        color: $block-more-color;

        &:hover {
            color: $block-more-hover;
            text-decoration: underline;
        }
    }

    .notify {
        padding: 10px;
        font-style: italic;
    }
}
