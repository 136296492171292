.request-thema {
    font-size: 1.1em;

    .page-title {
        width: 100%;
    }

    .submit-button {
        height: auto;
        margin: 8px;
        padding: 4px 8px;
        white-space: nowrap;
    }

    a {
        text-decoration: underline;

        &.submit-button {
            display: inline-block;
            text-decoration: none;
        }
    }

    .action-block {
        margin: 100px;
        text-align: center;

        @include breakpoint("before-medium") {
            margin: 0;
        }
    }

    .hidden {
        display: none;
    }

    .errorlist {
        color: $block-red;
    }

    .block.form {
        max-width: 400px;
        float: none;

        .required::after {
            content: " *";
        }

        textarea {
            display: block;
            width: 100%;
        }

        .block-header {
            background-color: $blue;
        }

        .form-row {
            margin: 5px 0;

            label {
                display: inline-block;
                width: 40%;
            }

            input,
            ul:not(.errorlist) {
                display: inline-block;
                width: 55%;
            }

            ul:not(.errorlist) {
                label {
                    width: auto;
                }

                input {
                    width: auto;
                    vertical-align: middle;
                }

                li {
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }
    }

    .block.narrow {
        width: auto;
    }

    table {
        font-size: .9em;

        thead {
            background-color: $block-blue;
            color: $white;
        }

        th,
        td {
            padding: .3em;
        }

        &.contracts {
            width: 100%;

            col {
                &.themas {
                    width: 50%;
                }

                &.actions {
                    width: 1%;
                }
            }
        }
    }
}
