.row.template-light-4-column {
    .horizontal-position {
        width: 100%;
        margin: auto;

        @include breakpoint("large") {
            width: 80%;
        }
    }
}
