@import "../../rainbow/scss/colors";

input[type="text"],
input[type="password"],
input[type="email"] {
    width: 90%;
    height: auto;
    margin-bottom: 4px;
    padding: 1px $unit;
    border: 1px solid $input-border;
    border-radius: 2px;
    line-height: 2em;

    @media only screen and (max-width: $mobile-max-viewport-width) {
        width: 100%;
    }
}

input[type="text"]:disabled {
    background-color: $input-background--disabled;
}

input[type="radio"] {
    margin-right: $small-icon-right-margin;
}

.input-row {
    display: block;
    margin-top: 4px;

    label {
        display: flex;
        align-items: center;
    }
}

select {
    display: block;
    height: 2em !important;
    padding: 1px 8px !important;
    border: 1px solid $input-border;
    border-radius: 2px;
    line-height: 2em;
}
