@import "../colors";

.block.license-plate-check {
    .block-content {
        padding: 2px 5px;
        background-color: $white;
    }

    .licenseplate {
        height: 30px;
        margin: 5px 0 8px;
        background: url("../../static/rainbow/images/license-plate-check/license-plate.gif") no-repeat;

        input {
            display: block;
            width: 120px;
            min-width: 25px;
            height: 30px;
            margin-left: 18px;
            border: 0;
            background: transparent;
            font-size: 18px;
        }
    }

    input {
        margin: 0 0 5px;
        float: right;
    }

    .autoweeklogo {
        width: 30px;
        height: 30px;
        margin: 4px 0 8px;
        float: right;
        background: url("../../static/rainbow/images/license-plate-check/logo.png") no-repeat;
        background-size: 30px;
    }
}
