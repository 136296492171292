@use "sass:math";

@mixin ellipsis($lines, $line-height: null) {
    $height: if($lines == 1, "height", "max-height");

    // Currently there  is no way to make non-webkit browsers
    // use ellipsis on second line (with css) so instead
    // they will cut text to last word that fits the block

    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    display: block; // Fallback for non-webkit
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;

    // On browsers that do not support line-clamp, we set the height (if only
    // one line should be shown) or max-height to hide the other lines.
    @if $line-height {
        line-height: $line-height;

        @if math.is-unitless($line-height) {
            #{$height}: ($line-height * $lines * 1em);
        } @else {
            #{$height}: ($line-height * $lines);
        }
    }
}
