@import "../../../rainbow/scss/widgets/contact";
@import "../../../profile/scss/spacing";

.contact-page-title {
    h1 {
        display: none;
    }
}

.g-recaptcha {
    margin-top: $doubleunit;
}

.block.thema-contact.bg-blue {
    .block-header {
        background-color: $block-blue;
    }

    h2 {
        background-color: $block-blue;
        color: $block-white;
    }
}
