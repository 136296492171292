// Based on https://github.com/eevee/eev.ee/blob/fde34e57adecb960e2d10ead0e69ebdc874279a6/theme/static/PARTYMODE/partymode.css
// Copyright ©2014, Lexy Munroe a.k.a. Eevee

// Permission to use, copy, modify, and/or distribute this software for any
// purpose with or without fee is hereby granted, provided that the above
// copyright notice and this permission notice appear in all copies.

// THE SOFTWARE IS PROVIDED "AS IS" AND THE AUTHOR DISCLAIMS ALL WARRANTIES
// WITH REGARD TO THIS SOFTWARE INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY
// AND FITNESS. IN NO EVENT SHALL THE AUTHOR BE LIABLE FOR ANY SPECIAL, DIRECT,
// INDIRECT, OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM
// LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE
// OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR
// PERFORMANCE OF THIS SOFTWARE.

.party-mode--container {
    position: fixed;
    z-index: 10000;
    inset: 0;
    overflow: hidden;
    transform: translateZ(0);
    pointer-events: none;
}

.party-mode--flake {
    position: absolute;
    top: 0;
    left: 0;
    animation-name: party-mode-flake1;
    animation-duration: 5s;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
}

@keyframes party-mode-flake1 {
    from {
        transform: translate(-10vw, -20vh);
    }

    to {
        transform: translate(10vw, 120vh);
    }
}
