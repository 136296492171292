/**
  Make the tailwind postcss-properties work well with Sass and linters
  Source: https://github.com/nicolassutter/Tailwind_loves_sass
  NPM Package did not work as intended, so the source was copied below.
*/

@mixin apply($classes) {
    @apply #{$classes};
}

@mixin layer($name) {
    @layer #{$name} {
        @content;
    }
}

@mixin respond-to($name) {
    @media (min-width: theme("screens.#{$name}")) {
        @content;
    }
}

@mixin screen($name) {
    @include respond-to($name) {
        @content;
    }
}
