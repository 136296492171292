* {
    box-sizing: border-box;

    &:focus-visible {
        outline: .125rem solid rgba(0 0 0 / 85%);
    }
}

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.svg-holder {
    display: block;

    svg {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.sp-container {
    width: 100%;
    min-width: auto;
    max-width: auto;
    margin: auto;
}

.row {
    @extend %clearfix;

    width: 90%;
    min-width: auto;
    max-width: 995px;
    margin: auto;
}
