@import "../../../rainbow/scss/settings";
@import "../../../rainbow/scss/colors";
@import "../../../umbrella/scss/mixins/breakpoint";

.lightbox-container.media-block {
    .overlay {
        z-index: 1000;
    }

    .lightbox {
        z-index: 1001;
        width: 90%;
        max-width: 860px; // for video; width & max-width are overwritten by in-line styles when used with images

        @include breakpoint("before-medium") {
            width: 100%;
        }
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 52px;

        a {
            display: flex;
            align-items: center;
        }

        .close .text {
            display: inline-block !important;
            color: $lightbox-header-button-subtitle;
            font-size: 14px;
        }

        .close .icon {
            display: inline-block;
            width: 32px;
            height: 32px;
            margin-left: 8px;
            padding: 8px;
            border: 1px solid $lightbox-header-button-border;
            border-radius: $border-radius;
            background-image: url("/static/rainbow/images/lightbox/close.svg");
            background-repeat: no-repeat;
            background-position: center;
        }

        .close:hover {
            cursor: pointer;
        }
    }

    .content {
        display: flex;
        justify-content: center;
        background-color: $grey;

        &[data-embed-url] { // Video only
            padding: 0 8px;

            iframe {
                border: none;
            }
        }
    }

    .footer {
        height: 49px; // 3px is added by the content-container = 52px total
    }

    .subscript {
        a {
            color: $block-black;

            &:hover {
                color: $block-black;
            }
        }
    }
}
