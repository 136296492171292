@import "../colors";

.block.rain-forecast {
    .radar {
        display: block;
        height: 0;

        // This is to preserve the aspect ratio when the element is resized.
        // See also https://daverupert.com/2012/04/uncle-daves-ol-padded-box/
        padding-bottom: 100%;
        background: url("https://www.weerplaza.nl/klanten/sanoma/startpagina_nl/radar400x400.gif") no-repeat center center;
        background-size: cover;
    }

    .block-content {
        position: relative;
    }

    .button {
        position: absolute;
        bottom: 11px;
        left: 10px;
        padding: 3px 5px;
        border-radius: 2px;
        background-color: $rain-forecast-button-background-color;
        font-size: 12px;
        text-decoration: none;
    }

    .button:hover {
        background-color: $rain-forecast-button-background-hover;
    }

    .button::after {
        content: "\25B8";
        position: relative;
        left: 2px;
        padding: 0 3px;
        border-radius: 3px;
        background-color: $rain-forecast-button-icon-background-color;
        color: $rain-forecast-button-icon-color;
    }
}

// Override some styles when this widget is placed in search.
.filtered-search .block.rain-forecast {
    // Instead of showing the whole (square) radar image, we zoom and crop it
    // a bit so that only the Netherlands are displayed.
    .radar {
        margin: 0 -5px;
        padding-bottom: 130%;
        background-position: 65%;
        background-size: 180%;
    }

    .button {
        bottom: 5px;
        left: 5px;
        font-size: 10px;
    }
}
