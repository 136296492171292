@use "sass:map";
@use "sass:list";
@use "sass:string";
@import "../_partials/breakpoints";

// Mobile first!
$breakpoints: (
    "tiny" : (max-width: $breakpoint-tiny),
    "small" : (min-width: $breakpoint-small),
    "only-small" : (min-width: $breakpoint-small, max-width: $breakpoint-only-small-top),
    "pmedium" : (min-width: $breakpoint-pmedium),
    "only-pmedium" : (min-width: $breakpoint-pmedium, max-width: $breakpoint-before-medium),
    "medium" : (min-width: $breakpoint-medium),
    "before-medium" : (max-width: $breakpoint-before-medium),
    "only-medium" : (min-width: $breakpoint-before-medium, max-width: $breakpoint-only-medium-top),
    "max-medium": (max-width: $breakpoint-max-medium),
    "large": (min-width: $breakpoint-large),
    "only-large": (min-width: $breakpoint-large, max-width: $breakpoint-only-large-top),
    "max-large": (max-width: $breakpoint-max-large),
    "ultra" : (min-width: $breakpoint-ultra),
);

@mixin breakpoint($name) {
    @if map.has-key($breakpoints, $name) {
        $current: map.get($breakpoints, $name);
        $query: "screen";

        @each $key, $value in $current {
            // stylelint-disable-next-line scss/no-duplicate-dollar-variables
            $query: list.append($query, string.unquote(" and (#{$key}:#{$value})"));
        }

        @media #{$query} {
            @content;
        }
    } @else {
        @warn "Couldn't find a breakpoint named `#{$name}`.";
    }
}
