@import "../colors";

// kept this file because it has a use case in
// sparkle/themas/scss/widgets/weather-block.scss

$icons: "d000", "d100", "d200", "d210", "d211", "d212", "d220", "d221", "d222", "d240",
    "d300", "d310", "d310", "d312", "d320", "d321", "d322", "d340", "d400", "d410", "d411",
    "d412", "d420", "d421", "d422", "d430", "d431", "d431", "d432", "d440", "d500", "d600",
    "n000", "n100", "n200", "n210", "n211", "n212", "n220", "n221", "n222", "n240", "n300",
    "n310", "n311", "n312", "n320", "n321", "n322", "n340", "n400", "n410", "n411", "n412",
    "n420", "n421", "n422", "n430", "n431", "n432", "n440", "n500", "n600";

@mixin weather-icon-path($icon) {
    background-image: url("/static/rainbow/images/weather/#{$icon}.svg");
}

@each $icon in $icons {
    .weather-icon-#{$icon} {
        @include weather-icon-path(#{$icon});

        &.weather-icon-large-in-section-wide {
            .section-wide & {
                @include weather-icon-path(#{$icon});
            }
        }
    }
}

%block-weather,
%block.weather,
.block.weather {
    .block-content {
        padding: 0;
        color: $block-blue;

        .location {
            margin: 0;
        }

        a:hover {
            color: $black;
        }
    }

    .weather-today,
    .weather-forecast {
        padding: 5px;
        overflow: auto;
    }

    .weather-today {
        a {
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                text-decoration: none;
            }
        }

        .weather-icon {
            flex-shrink: 0;
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .weather-temperature {
            flex-shrink: 0;
            margin-left: 8px;
            color: $weather-widget-temperature-color;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
        }

        .weather-summary {
            display: -webkit-box;
            margin-left: 8px;
            padding-right: 4px;
            overflow: hidden;
            font-size: 13px;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .weather-forecast {
        border-top: 1px solid $block-widget-input-text-border-color;
        text-align: center;

        .weather-day {
            width: 25%;
            margin-bottom: 5px;
            padding-top: 5px;
            float: left;

            &:hover {
                .weather-day-title {
                    text-decoration: underline;
                }
            }

            .weather-icon {
                height: 30px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;

                .section-wide & {
                    height: 65px;
                }
            }

            .weather-day-title {
                margin-bottom: 5px;
                font-size: 13px;
            }

            .weather-temperature {
                font-size: 12px;

                .weather-minimum-temperature {
                    color: $block-grey;
                }

                .weather-maximum-temperature {
                    color: $weather-widget-temperature-color;
                    font-weight: bold;
                }
            }
        }
    }

    .notify {
        padding: 10px;
        font-style: italic;
    }
}
