.light-5-column-top-3-left {
    .section {
        &.section-wide {
            float: left;

            @include breakpoint("small") {
                width: 100%;
            }

            @include breakpoint("medium") {
                width: 100%;
            }
        }
    }
}
