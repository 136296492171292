@import "colors";
@import "settings";

.not-found {
    font-size: 14px;

    .row {
        margin-bottom: 10px;
        padding: 15px;
        border: 1px solid $not-found-warning-border;
        border-top-width: 0;
        border-radius: 0 0 $border-radius $border-radius;
        background-color: $not-found-warning-background;
    }
}
