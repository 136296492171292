@import "../../../rainbow/scss/colors";
@import "./media-lightbox";

.block.video-block {
    .block-content {
        padding: 0;

        .thumbnail {
            display: block;
            position: relative;

            img {
                width: 100%;
            }

            .play-video-btn {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                background: url("/static/themas/icons/youtube-play.png") no-repeat center center;
            }

            &:hover .play-video-btn {
                background-image: url("/static/themas/icons/youtube-play-hover.png");
            }
        }
    }

    .subscript {
        margin: 5px 8px 8px;
        color: $block-black;
        font-style: italic;

        a {
            color: $block-black;

            &:hover {
                color: $block-hover;
            }
        }
    }
}
