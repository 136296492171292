@import "../../colors";
@import "../../settings";

.ui-autocomplete.search {
    position: absolute;

    // The autocomplete should have a z-index higher than the searchbox and
    // lightbox to make sure it's not hidden by them.
    z-index: 30;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    border-radius: 0 0 $border-radius $border-radius;
    border-color: $searchbox-autocompete-border;
    background-color: $white;
    list-style: none;

    li.ui-menu-item {
        height: 25px;
        padding: 5px;
        cursor: pointer;
    }
}

.ui-helper-hidden-accessible {
    display: none;
}
