@import "../../rainbow/scss/colors";

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 9px;
    border: none;
    border-radius: 2px;
    background-color: $orange;
    color: $block-white;
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;

    .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .link-image {
        display: inline-block;
        margin-right: 9px;
        background-size: cover;
    }

    & > .profile-icon:has(+ span) {
        margin-inline-end: .5rem;
    }

    & > span + .profile-icon {
        margin-inline-start: .5rem;
    }
}

.button-sim {
    width: 35%;
}

.button-primary {
    width: 45%;
    float: right;

    &:hover {
        background-color: var(--orange-400);
    }
}

.button-secondary {
    width: 45%;
    background-color: $blue;
    color: $white;
}

.button-reverse {
    border: 1px solid $orange;
    background-color: $white;
    color: $orange;

    &:hover {
        border-color: var(--orange-400);
        color: var(--orange-400);
    }
}
