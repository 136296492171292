.block.about-author {
    .block-content {
        padding: 0;

        img {
            width: 100%;
        }

        > :not(img) {
            padding: 8px;
        }

        a {
            display: inline-block;
            text-decoration: underline;
        }
    }
}
