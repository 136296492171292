@import "../colors";
@import "../../../profile/scss/spacing";

$mobile-max-viewport-width: 500px; // integrate into responsive system during refactoring

// TODO: Fix this in SPR-1666 (ThemaContact was created to avoid name conflicts)
.block.thema-contact,
.block.contact {
    /* stylelint-disable no-invalid-position-at-import-rule */
    @import "../../../profile/scss/block";
    @import "../../../profile/scss/input";
    @import "../../../profile/scss/button";
    /* stylelint-enable no-invalid-position-at-import-rule */

    .block-content {
        box-sizing: border-box;
        background-color: $block-lightblue;

        form {
            padding: 0;
            overflow: hidden;
        }

        label {
            display: block;
            margin-bottom: $unit;

            &.is-required::after {
                content: " *";
                color: $block-red;
            }
        }

        .contact-select {
            height: calc(2em + 4px) !important;
            margin-bottom: $unit * 1.5;
            background-color: $white;
        }

        #editor-select {
            @media only screen and (max-width: $mobile-max-viewport-width) {
                width: 100%;
            }
        }

        .contact-title {
            width: 30%;
            margin-bottom: $unit;
            float: left;
        }

        .subject {
            width: 100%;
        }

        .contact-name {
            width: 100%;
        }

        .contact-name-wrapper {
            display: block;
            padding-left: $unit;
            overflow: hidden;
        }

        .contact-email {
            display: block;
            width: 100%;
            margin-bottom: $unit;
        }

        #contact-email-confirm {
            margin-bottom: $unit * 1.5;
        }

        .errors {
            display: none;
            margin-bottom: $doubleunit;
            color: $block-red;
            font-weight: bold;
        }

        .onsubmit-message {
            display: none;
        }

        .text-bold {
            font-weight: bold;
        }

        .contact-submit {
            width: 25%;
            min-width: 110px;
            margin-top: $doubleunit;
            float: left;
        }

        .button-redirect {
            width: 35%;
            min-width: 220px;
        }

        .button {
            @media only screen and (max-width: $mobile-max-viewport-width) {
                width: 100%;
            }
        }

        textarea {
            width: 100%;
            height: 200px;
            margin-bottom: $halfunit;
            border: 1px solid $input-border;
            resize: none;
        }

        .attachment {
            display: block;
            height: auto;
            border: 0;
            line-height: inherit;
        }
    }
}
