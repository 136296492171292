@each $name, $color in $background-colors {
    body.bg-#{$name} {
        background-color: $color;
    }
}

body.bg-black,
body.bg-red {
    .bg-transparent[data-widget="Html"] * {
        color: #fff;
    }
}

.footer {
    .disclaimer {
        background: $white;
    }
}
