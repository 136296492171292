.light-5-column-top-3-center {
    .responsive-column {
        // to offset the padding of the first column:
        // the padding there is necessary because in the current model
        // it is included in the column width calculation
        margin: 0 calc($column-spacing / 2) 0 calc($column-spacing / -2);

        @include breakpoint("large") {
            width: 78.8%;
        }
    }

    .section {
        @include breakpoint("large") {
            width: 25%;
        }

        &.section-wide {
            @include breakpoint("large") {
                width: 75%;
            }
        }
    }

    .column4 {
        @include breakpoint("large") {
            width: 25%;

            .section {
                width: 100%;
            }
        }
    }

    .column5 {
        @include breakpoint("large") {
            width: 21.2%;

            .section {
                width: 100%;
            }
        }
    }
}
