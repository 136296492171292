@import "../colors";

.block.traffic-map {
    .block-content {
        padding: 0;

        .tab-container {
            display: inline-block;
            width: 100%;
            margin-top: 10px;
            box-shadow: inset 0 -20px 47px -26px $traffic-map-tab-shadow-color;

            .tab {
                display: block;
                width: calc(50% - 21px);
                margin: 0 10px;
                padding: 10px;
                float: left;
                border: 1px solid $traffic-map-tab-border-color;
                border-bottom: 0;
                border-radius: 5px 5px 0 0;
                background-color: $traffic-map-tab-unselected-background-color;
                box-shadow: inset 0 -20px 47px -26px $traffic-map-tab-shadow-color;
                font-weight: bold;
                cursor: pointer;

                &.selected {
                    border-bottom: 1px solid $block-content-background;
                    background-color: $block-content-background;
                    box-shadow: none;
                }
            }
        }

        .traffic-map-no-results {
            padding: 20px;
            font-weight: bold;
        }

        .traffic-map-road-number {
            width: 40px;
            margin: 5px 20px;
            padding: 2px;
            float: left;
            clear: both;
            font-weight: bold;
            text-align: center;

            &.a-road {
                border: 1px solid $traffic-map-road-number-a-border-color;
                background-color: $traffic-map-road-number-a-background-color;
                color: $traffic-map-road-number-a-color;
            }

            &.n-road {
                border: 1px solid $traffic-map-road-number-n-border-color;
                background-color: $traffic-map-road-number-n-background-color;
                color: $traffic-map-road-number-n-color;
            }
        }

        .traffic-map-list-container {
            display: inline-block;
            padding-top: 20px;

            .traffic-map-list {
                &.speed-traps {
                    display: none;
                }

                .speed-trap-route,
                .traffic-jam-route,
                .traffic-jam-delay {
                    font-weight: bold;
                }

                .traffic-map-list-info {
                    margin-bottom: 10px;
                    float: left;
                    line-height: 20px;
                }
            }
        }
    }

    .region {
        padding: 8px;
    }

    .map {
        img {
            display: block;
            width: 100%;
        }
    }
}
