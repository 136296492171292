.videowidget .block.rss-feed {
    .block-content {
        padding: 0;
    }

    li {
        padding: 0 2px;

        a {
            display: block;
            width: calc(100% - 10px);
            padding: 2px 0 2px 15px;
            overflow: hidden;
            background-image: url("/static/rainbow/images/news-rss/baseline-keyboard-arrow-right-20px-black.svg");
            background-repeat: no-repeat;
            background-position: 0 2px;
            background-size: 15px 15px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
