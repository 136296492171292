@import "base/tailwind-sass";
@import "base/colors";
@import "base/tailwind-base";
@import "base/svg-icon-helper";

// Maximum width of site-content, enforced using .max-content-width
$max-content-width: 67.25rem;
$max-content-width-narrow: 52.5rem;

.max-content-width {
    max-width: $max-content-width;
}

// narrow variant for 4 column layout to test DPG banners
.max-content-width-narrow {
    max-width: $max-content-width-narrow;
}

// Specify #app to prevent existing icons from breaking
#app svg {
    width: inherit;
    height: inherit;
}

// Cancel styles on Tailwind wrapper
// .block is still needed for responsive layout
#app.block {
    margin-bottom: 0;
    box-shadow: none;
}

@include layer("utilities") {
    .stack-y {
        @include apply("flex flex-col");
    }

    .stack-x {
        @include apply("flex flex-row");
    }
}

.margin-bottom-safe-area {
    @supports (margin-bottom: env(safe-area-inset-bottom)) {
        margin-bottom: env(safe-area-inset-bottom);
    }
}

// Custom scenario where we need a gray backdrop for footer items
@media screen and (min-width: 600px) {
    .more-menu {
        background: linear-gradient(to top, var(--gray-100) 5rem, var(--white) 5rem, var(--white));
    }
}

[x-cloak] { display: none !important; }

// Auth widget styling fixes
#authwidgets_account-button-wrapper .account-button {
    .text {
        @include apply("leading-3");
    }

    // Specificity hack
    &.account-button:hover {
        background-color: var(--orange-500);
    }
}

// Remove box-shadow from search-box on mobile
.header-section .block.search-box {
    box-shadow: none;

    form .search-field {
        font-size: 1rem;
    }
}

// Exception for class .invisible being used on blocks to hide header
#app .block.invisible {
    visibility: visible;

    [x-ref="BlockHeader"] {
        display: none;
    }

    [x-ref="BaseContent"] {
        border-radius: .125rem;
    }
}

// Hide box-shadow for .bg-transparent
#app .block.bg-transparent {
    box-shadow: none;

    .shadow-block {
        box-shadow: none;
    }
}

// Remove slow transition on hovers
#app a {
    transition: none;
}
