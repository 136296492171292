.block.thema-banner {
    float: none;
    box-shadow: none;
}

.block.thema-banner.v5.sticky {
    position: sticky;
    top: 16px;
    margin-bottom: 16px;
}

.top-banner .thema-banner {
    text-align: center;

    .ad-widget > * {
        margin: 9px 0 7px;
    }
}
