@import "../colors";

.block.insurance {
    .block-content {
        background-color: transparent;
    }

    .frame-container {
        min-height: 1100px;
    }

    iframe {
        width: 1px;
        min-width: 100%;
        min-height: 1100px;
        margin-bottom: 10px;
        border: 0;
    }
}

.insurance-nav {
    display: block;
    position: relative;
    width: 100%;
    height: 45px;
    overflow: auto hidden;
    white-space: nowrap;

    ul {
        display: inline-block;
        position: relative;
        height: 32px;
        list-style: none;
    }

    li {
        display: inline-block;
        height: 32px;
        margin: 0;
        text-align: center;
    }

    .insurance-tab {
        margin-top: 2px;
        padding: 0;
        border-radius: 2px;
        background-color: $insurance-tab-background-color;
        color: $insurance-tab-text-color;
        font-weight: bold;
        line-height: 17px;

        &:first-child {
            margin-left: 8px;
        }

        &:hover {
            background-color: $insurance-tab-hover-background-color;
            color: $white;
            cursor: pointer;
        }

        &.active {
            background-color: $insurance-tab-active-background-color;
            color: $white;
            text-decoration: underline;
        }

        span {
            display: block;
            padding: 6px 30px;
            font-size: 13px;
        }
    }
}
