@use "sass:color";
@import "colors";
@import "settings";

.submit-button {
    padding: 0 8px;
    transition: all .15s ease-in-out;
    border: none;
    border-radius: $border-radius;
    background-color: $startpagina-knop;
    color: $white;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;

    &:hover {
        background-color: $startpagina-knop-hover;
    }

    &.blue {
        background-color: $blue;

        &:hover {
            background-color: color.adjust($blue, $lightness: 15%);
        }
    }

    &.submit-button-large {
        height: 35px;
        padding: 0 22px;
        line-height: 35px;
    }

    &.with-icon {
        .icon {
            display: inline-block;
            position: relative;
            top: 4px;
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
        }

        .text {
            margin-left: 2px;
        }
    }
}
