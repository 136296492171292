@import "colors";

a {
    color: $black;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.bold {
    font-weight: bold;
}

.highlight {
    background-color: $highlight;
}

.cookie-consent-message {
    a {
        color: $block-more-color;
    }
}
