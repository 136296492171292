.light-4-column {
    .responsive-column {
        // to offset the padding of the first column:
        // the padding there is necessary because in the current model
        // it is included in the column width calculation
        margin: 0 calc($column-spacing / 2) 0 calc($column-spacing / -2);

        @include breakpoint("large") {
            position: relative;
            width: 80%;
        }
    }

    .section {
        @include breakpoint("large") {
            width: 25%;
        }

        &.section-wide {
            @include breakpoint("large") {
                width: 75%;
            }
        }
    }

    .column4 {
        @include breakpoint("large") {
            width: 25%;

            .section {
                width: 100%;
            }
        }
    }

    // Tailwind is scoped to #app so we need the high specificity of !important
    .link-block .bg-block-bg-default {
        background-color: white !important;
    }

    .right-column,
    .left-column {
        background-color: unset;

        @include breakpoint("large") {
            .section {
                width: 100%;
                padding: 0 8px;
            }
        }
    }

    .right-column {
        @include breakpoint("large") {
            width: 160px;
        }
    }

    .left-column {
        @include breakpoint("large") {
            width: 10%;
        }
    }
}

.themas .block.bg-default .block-content {
    background-color: white !important;
}

.template-light-4-column {
    background-color: unset;
}
